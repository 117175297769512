import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let url = '/stores/onlinestore/themes';
function get() {
  return storeRequest.get(`${url}`);
}

function getConfig(id) {
  return storeRequest.get(`${url}/${id}/pages`);
}

function createConfig(menu) {
  return storeRequest.post(url, menu);
}

function updateConfig(idThems, idPage, data) {
  return storeRequest.put(`${url}/${idThems}/pages/${idPage}`, data);
}

function changeTheme(name) {
  return storeRequest.put(`/stores/themeTypes/${name}`);
}
function getTemplateTheme(name) {
  return storeRequest.get(`/stores/onlinestore/templates`);
}
function cloneTheme(idTheme) {
  return storeRequest.post(`${url}/${idTheme}/clone`);
}
function activeTheme(idTheme) {
  return storeRequest.post(`${url}/${idTheme}/activate`);
}
function removeTheme(idTheme) {
  return storeRequest.delete(`${url}/${idTheme}`);
}
function addTheme(data) {
  return storeRequest.post(`${url}/add`, data);
}
function addTemplate(id) {
  return storeRequest.post(`/stores/onlinestore/template/${id}`);
}
export const apiThemes = {
  get,
  createConfig,
  getConfig,
  updateConfig,
  changeTheme,
  getTemplateTheme,
  cloneTheme,
  activeTheme,
  removeTheme,
  addTheme,
  addTemplate,
};
